import React, { useEffect, useMemo, useState } from 'react'
import '../../../../../Table/TableBody/TableModal/TableModal.scss'
import { ReactComponent as Copy } from '../../../../../../assets/svg/Copy.svg'
import { ReactComponent as Export } from '../../../../../../assets/svg/Export.svg'
import { ReactComponent as Status } from '../../../../../../assets/svg/Status.svg'
import { ReactComponent as NewOrder } from '../../../../../../assets/svg/NewOrder.svg'
import { ReactComponent as Truck } from '../../../../../../assets/svg/Truck.svg'
import { ReactComponent as Delete } from '../../../../../../assets/svg/Delete.svg'
import { mainApi } from '../../../../../../store/services/mainApi'
import { useDispatch } from 'react-redux'

import { ReactComponent as Production } from '../../../../../../assets/svg/cap.svg'
import { ReactComponent as Delivery } from '../../../../../../assets/svg/scooterDelivery.svg'
import { ReactComponent as CONGEDOR } from '../../../../../../assets/svg/Congelador.svg'
import { ReactComponent as Like } from '../../../../../../assets/svg/Like.svg'
import { ReactComponent as InProgress } from '../../../../../../assets/svg/inProgress.svg'
// import { ReactComponent as NotAvalible } from '../../../../../../assets/svg/NotAvalible.svg'
import { ReactComponent as Plus } from '../../../../../../assets/svg/Plus.svg'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'

// import { changeItems } from '../../../../../../store/slices/changeItems'
import { useSelector } from 'react-redux'
import { Box, Button, MenuItem, Modal, Select, TextField, useMediaQuery } from '@mui/material'

export default function OrderChangeTableBodyModal({ setSelectedItems, order, selectedItems, editOrder }) {
  const mobileScreen = useMediaQuery('(max-width: 768px)')
  const [createOrder] = mainApi.useCreateNewOrderMutation()
  const [copyImages] = mainApi.useChangeItemsInOrderMutation()
  const [changeItems] = mainApi.useChangeItemsInOrderMutation()
  const [sendConf] = mainApi.useSendConfirmationWAMutation()
  const [createDriver] = mainApi.useDriverListCreateMutation()
  const [deleteDriver] = mainApi.useDriverListDeleteMutation()
  const [updateDriver] = mainApi.useDriverListUpdateMutation()

  const [changeModal, setChangeModal] = useState(false)
  const [selectedContact, setSelectedContact] = useState({})
  const [selectedContactName, setSelectedContactName] = useState('')
  const [selectedContactPhone, setSelectedContactPhone] = useState('')

  const [createModal, setCreateModal] = useState(false)
  const [createName, setCreateName] = useState('')
  const [createPhone, setCreatePhone] = useState('')

  useEffect(() => {
    if (selectedContact) {
      console.log(selectedContact)

      setSelectedContactName(selectedContact.name)
      setSelectedContactPhone(selectedContact.phone)
    }
  }, [selectedContact])

  const handleDeleteDriver = async () => {
    if (confirm('Are you sure you want to delete this driver?')) {
      await deleteDriver(selectedContact._id).then(() => {
        dispatch(mainApi.util.invalidateTags(['Drivers']))
        setChangeModal(false)
      })
      setSelectedContact({})
    }
  }

  const handleCreateDriver = async () => {
    if (confirm('Are you sure you want to create this driver?')) {
      await createDriver({
        name: createName,
        phone: createPhone,
      })
        .then(() => {
          dispatch(mainApi.util.invalidateTags(['Drivers']))
          setCreateModal(false)
        })
        .catch((err) => {
          alert(err)
        })
    }
  }

  const handleUpdateDriver = async () => {
    if (confirm('Are you sure you want to update this driver?')) {
      await updateDriver({
        _id: selectedContact._id,
        name: selectedContactName,
        phone: selectedContactPhone,
      }).then(() => {
        dispatch(mainApi.util.invalidateTags(['Drivers']))
        setChangeModal(false)
      })
    }
  }

  const { data: drivers } = mainApi.useGetDriversQuery()

  const dispatch = useDispatch()
  const productsIds = selectedItems.map((item) => item._id)

  const { status } = order

  const handleExportProducts = () => {
    window.open(`https://tpomobi.shop/exportProductAdmin?_ids=${productsIds.join(',')}`)
    setSelectedItems([])
  }
  const createNewOrderInChangeOrder = async () => {
    if (confirm('Confirm order?')) {
      let body = {
        data: {
          img: 'https://imgur.com/b6ByMHk.jpg',
          items: selectedItems.map((el) => {
            return { ...el }
          }),
          date: Date.now(),
          type: 'browser',
          status: {
            color: '#5ad1a5',
            desc: '',
            name: 'Partnership',
            type: 'orders',
            _id: '65ea14fef2ce5f8bdbb131ab',
          },
          order: 'CP',
          description: order._id.slice(-6),
          paidValue: 0,
          userAcc: {
            ...order.userAcc,
          },
          tradeName: localStorage.getItem('name'),
          realOrderID: order._id,
        },
      }
      await createOrder(body)
      dispatch(mainApi.util.invalidateTags(['ShipmentOrders']))
      setSelectedItems([])
    }
  }

  const handleCopyProducts = async () => {
    const copyItems = selectedItems.map((item) => ({
      ...item,
      name: item.name + '(copy)',
    }))
    await copyImages({
      elem: {
        ...order,
        items: [...copyItems, ...order.items],
      },
    })
    dispatch(mainApi.util.invalidateTags(['Order']))
    setSelectedItems([])
  }

  const handleDeleteProducts = async (type) => {
    if (!order) {
      console.error('Order is null.')
      return
    }

    if (!order.items) {
      console.error('Order items are null.')
      return
    }

    if (type === 'copy') {
      if (confirm('Ok = Moved and Delete! \nCancel = Copy and Moved!') !== true) {
        return
      }
    }

    if (type === 'delete') {
      if (confirm('Delete?') !== true) {
        return
      }
    }

    const info = {
      data: {
        items: [...order.items],
        orderID: order._id,
      },
    }

    try {
      const response = await fetch('https://tpomobi.shop/mondayDeleteItemZumos', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(info),
      })

      if (!response.ok) {
        throw new Error('Response not ok.')
      }

      const data = await response.json()
      console.log('Success:', data)
    } catch (error) {
      console.error('Error:', error)
    }

    const newItems = order.items.filter((item) => !selectedItems.includes(item))
    await copyImages({
      elem: {
        ...order,
        items: [...newItems],
      },
    })
    setTimeout(() => {
      dispatch(mainApi.util.invalidateTags(['Order']))
    }, 500)
    setSelectedItems([])
  }

  //Modal Status
  const [statusModal, setStatusModal] = useState(false)

  const { data: statusesData, isLoading } = mainApi.useGetAllStatusesQuery()

  const [statuses, setStatuses] = useState([])

  useEffect(() => {
    if (statusesData) {
      setStatuses(statusesData.filter((status) => status.type === 'itemsInOrder'))
    }
  }, [statusesData])

  const handleOpenModal = () => {
    if (editOrder) {
      setStatusModal(!statusModal)
    } else {
      alert('Press edit button')
    }
  }

  const [changestatus, setChangeStatus] = useState(status)

  const handleChangeStatus = (status) => {
    const newItems = order.items.map((item) => {
      if (selectedItems.includes(item)) {
        return {
          ...item,
          status,
        }
      } else {
        return item
      }
    })
    changeItems({
      elem: {
        ...order,
        items: [...newItems],
      },
    })
      .unwrap()
      .then(() => {
        dispatch(mainApi.util.invalidateTags(['Order']))
      })

    setSelectedItems([])
  }

  const total = useMemo(() => {
    if (selectedItems) {
      return selectedItems.reduce((acc, item) => acc + (item.marketPriceCP || 0) * (item.quantity || 0), 0)
    } else {
      return 0
    }
  }, [selectedItems])

  const cogs = useMemo(() => {
    if (selectedItems) {
      return selectedItems.reduce((acc, item) => acc + (item.ourPrice || 0) * (item.quantity || 0), 0)
    } else {
      return 0
    }
  }, [selectedItems])

  const profit = useMemo(() => {
    return total - cogs
  }, [total, cogs])

  useEffect(() => {
    console.log(selectedItems, 'selectedItems')
  }, [selectedItems])

  const handleCreateNewOrder = async () => {
    const { _id, ...orderWithoutId } = order // Удаление свойства _id из объекта order
    const body = {
      data: {
        ...orderWithoutId, // Использование копии order без свойства _id
        items: selectedItems,
        date: Date.now(),
        status: {},
        description: `Created from ${order.particalId ? order.particalId : _id}. Moved ${selectedItems.length} items`, // Используем _id, который мы извлекли ранее, если particalId отсутствует
      },
    }
    await createOrder(body)
      .then(() => {
        handleDeleteProducts('copy')
        alert('Success')
      })
      .catch((err) => alert(err))
    console.log(body, 'body')
  }

  //send confirm

  const [sendConfModal, setSendConfModal] = useState(false)

  const sendConfirmation = async (driverPhone) => {
    const updatedItems = selectedItems.map((item) => {
      const quantity = prompt('Enter quantity for ' + item.name)

      return {
        ...item,
        promptQuantity: parseInt(quantity) || 0, // Ensure quantity is an integer, default to 0 if invalid
      }
    })

    // Update state with the new items with quantities
    setSelectedItems(updatedItems)

    // Ensure updatedItems are used instead of relying on asynchronous state updates
    const body = {
      data: {
        acc: order.userAcc,
        order: order,
        confItems: updatedItems, // Use updatedItems instead of selectedItems
        driverPhone,
      },
    }

    try {
      await sendConf(body)
      alert('Success')
      setSendConfModal(false)
      setSelectedItems([])
    } catch (err) {
      alert(err)
    }
  }

  return (
    <div style={{ display: selectedItems.length > 0 ? 'flex' : 'none' }} className='tableModal'>
      <div style={{ display: 'flex', justifyContent: 'space-around' }} className='tableModal__title'>
        <span>Selected Rows</span>
        <span style={{ fontSize: '40px' }}>{selectedItems.length}</span>
      </div>
      <div className='vertical__line'></div>
      <div className='tableModal__body'>
        <div className='tableModal__body__item' onClick={createNewOrderInChangeOrder}>
          <Copy width={30} height={30} />
          <p>Buy Products</p>
        </div>
        <div onClick={handleCreateNewOrder} className='tableModal__body__item'>
          <Copy width={30} height={30} />
          <p>Create new</p>
        </div>
        <div className='tableModal__body__item' onClick={handleCopyProducts}>
          <Copy width={30} height={30} />
          <p>Copy</p>
        </div>
        <div className='tableModal__body__item' onClick={handleExportProducts}>
          <Export width={30} height={30} />
          <p>Export</p>
        </div>
        <div onClick={handleOpenModal} className='tableModal__body__item'>
          <Status width={30} height={30} />
          <p>Status</p>
        </div>
        <div onClick={() => setSendConfModal(true)} className='tableModal__body__item'>
          <WhatsAppIcon
            sx={{
              width: '30px',
              height: '30px',
            }}
          />
          <p>WhatsApp</p>
          <Modal
            open={sendConfModal}
            onClose={(e) => {
              e.stopPropagation()
              setSendConfModal(false)
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                maxWidth: 300,
                maxHeight: '80vh',
                // height: 300,
                width: '300px',
                bgcolor: 'background.paper',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                borderRadius: '10px',
                boxShadow: 24,
                padding: '15px',
                overflow: 'auto',
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', maxHeight: '300px', overflow: 'auto', padding: '10px' }}>
                {drivers
                  ? drivers.map((driver) => (
                      <Box
                        sx={{
                          fontSize: '16px',
                          textAlign: 'left',
                          marginBottom: '10px',
                          padding: '5px',
                          ':hover': { cursor: 'pointer', backgroundColor: 'gray', borderRadius: '5px' },
                        }}
                        key={driver._id}
                        onClick={() => sendConfirmation(driver.phone)}
                      >
                        {driver.name}
                      </Box>
                    ))
                  : null}
                {/* <Box
                  onClick={() => sendConfirmation('375298148709')}
                  sx={{
                    fontSize: '16px',
                    textAlign: 'left',
                    marginBottom: '10px',
                    padding: '5px',
                    ':hover': { cursor: 'pointer', backgroundColor: 'gray', borderRadius: '5px' },
                  }}
                >
                  YuraCH
                </Box>
                <Box
                  onClick={() => sendConfirmation('34642868541')}
                  sx={{
                    fontSize: '16px',
                    textAlign: 'left',
                    marginBottom: '10px',
                    padding: '5px',
                    ':hover': { cursor: 'pointer', backgroundColor: 'gray', borderRadius: '5px' },
                  }}
                >
                  Den
                </Box>
                <Box
                  onClick={() => sendConfirmation('34600043489')}
                  sx={{
                    fontSize: '16px',
                    textAlign: 'left',
                    marginBottom: '10px',
                    padding: '5px',
                    ':hover': { cursor: 'pointer', backgroundColor: 'gray', borderRadius: '5px' },
                  }}
                >
                  Levitskiy Nazar
                </Box>
                <Box
                  onClick={() => sendConfirmation('34642774935')}
                  sx={{
                    fontSize: '16px',
                    textAlign: 'left',
                    marginBottom: '10px',
                    padding: '5px',
                    ':hover': { cursor: 'pointer', backgroundColor: 'gray', borderRadius: '5px' },
                  }}
                >
                  Zasedko Andrey
                </Box>
                <Box
                  onClick={() => sendConfirmation('34631334790')}
                  sx={{
                    fontSize: '16px',
                    textAlign: 'left',
                    marginBottom: '10px',
                    padding: '5px',
                    ':hover': { cursor: 'pointer', backgroundColor: 'gray', borderRadius: '5px' },
                  }}
                >
                  Cires Claudia
                </Box>
                <Box
                  onClick={() => sendConfirmation('34602179471')}
                  sx={{
                    fontSize: '16px',
                    textAlign: 'left',
                    marginBottom: '10px',
                    padding: '5px',
                    ':hover': { cursor: 'pointer', backgroundColor: 'gray', borderRadius: '5px' },
                  }}
                >
                  Cires Slava
                </Box>

                <Box
                  onClick={() => sendConfirmation('34661192466')}
                  sx={{
                    fontSize: '16px',
                    textAlign: 'left',
                    marginBottom: '10px',
                    padding: '5px',
                    ':hover': { cursor: 'pointer', backgroundColor: 'gray', borderRadius: '5px' },
                  }}
                >
                  Elena Kizhikina
                </Box>
                <Box
                  onClick={() => sendConfirmation('34613077748')}
                  sx={{
                    fontSize: '16px',
                    textAlign: 'left',
                    marginBottom: '10px',
                    padding: '5px',
                    ':hover': { cursor: 'pointer', backgroundColor: 'gray', borderRadius: '5px' },
                  }}
                >
                  Artem Kozhakin
                </Box>
                <Box
                  onClick={() => sendConfirmation('34672238049')}
                  sx={{
                    fontSize: '16px',
                    textAlign: 'left',
                    marginBottom: '10px',
                    padding: '5px',
                    ':hover': { cursor: 'pointer', backgroundColor: 'gray', borderRadius: '5px' },
                  }}
                >
                  Pychka Yuri
                </Box>
                <Box
                  onClick={() => sendConfirmation('34663945962')}
                  sx={{
                    fontSize: '16px',
                    textAlign: 'left',
                    marginBottom: '10px',
                    padding: '5px',
                    ':hover': { cursor: 'pointer', backgroundColor: 'gray', borderRadius: '5px' },
                  }}
                >
                  Aleksandr Klim
                </Box>
                <Box
                  onClick={() => sendConfirmation('34678976664')}
                  sx={{
                    fontSize: '16px',
                    textAlign: 'left',
                    marginBottom: '10px',
                    padding: '5px',
                    ':hover': { cursor: 'pointer', backgroundColor: 'gray', borderRadius: '5px' },
                  }}
                >
                  Cires Ion
                </Box>
                <Box
                  onClick={() => sendConfirmation('34688654044')}
                  sx={{
                    fontSize: '16px',
                    textAlign: 'left',
                    marginBottom: '10px',
                    padding: '5px',
                    ':hover': { cursor: 'pointer', backgroundColor: 'gray', borderRadius: '5px' },
                  }}
                >
                  Yussupov Nail
                </Box>
                <Box
                  onClick={() => sendConfirmation('34600534829')}
                  sx={{
                    fontSize: '16px',
                    textAlign: 'left',
                    marginBottom: '10px',
                    padding: '5px',
                    ':hover': { cursor: 'pointer', backgroundColor: 'gray', borderRadius: '5px' },
                  }}
                >
                  Aleksandr Agafonov
                </Box>
                <Box
                  onClick={() => sendConfirmation('34688384905')}
                  sx={{
                    fontSize: '16px',
                    textAlign: 'left',
                    marginBottom: '10px',
                    padding: '5px',
                    ':hover': { cursor: 'pointer', backgroundColor: 'gray', borderRadius: '5px' },
                  }}
                >
                  Horokhov Roman
                </Box>
                <Box
                  onClick={() => sendConfirmation('34644208371')}
                  sx={{
                    fontSize: '16px',
                    textAlign: 'left',
                    marginBottom: '10px',
                    padding: '5px',
                    ':hover': { cursor: 'pointer', backgroundColor: 'gray', borderRadius: '5px' },
                  }}
                >
                  Mikhail Sadovsky
                </Box>
                <Box
                  onClick={() => sendConfirmation('34632389119')}
                  sx={{
                    fontSize: '16px',
                    textAlign: 'left',
                    marginBottom: '10px',
                    padding: '5px',
                    ':hover': { cursor: 'pointer', backgroundColor: 'gray', borderRadius: '5px' },
                  }}
                >
                  Vladimir Petrov
                </Box> */}
              </Box>
              <Box>
                <Button onClick={() => setChangeModal(true)} variant='contained' fullWidth>
                  Edit
                </Button>
                <Modal
                  open={changeModal}
                  onClose={() => {
                    setChangeModal(false)
                  }}
                >
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      width: 300,
                      height: 300,
                      bgcolor: 'background.paper',
                      border: '2px solid #000',
                      boxShadow: 24,
                      p: 4,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Select value={selectedContact} onChange={(e) => setSelectedContact(e.target.value)} sx={{ width: '100%' }}>
                      {drivers &&
                        drivers.map((driver) => (
                          <MenuItem key={driver._id} value={driver}>
                            {driver.name}
                          </MenuItem>
                        ))}
                    </Select>

                    <TextField
                      value={selectedContactName}
                      onChange={(e) => setSelectedContactName(e.target.value)}
                      sx={{ width: '100%' }}
                      // label='Name'
                    />
                    <TextField
                      value={selectedContactPhone}
                      onChange={(e) => setSelectedContactPhone(e.target.value)}
                      sx={{ width: '100%' }}
                      // label='Name'
                    />

                    <Button onClick={() => handleUpdateDriver()} variant='contained' fullWidth>
                      Save
                    </Button>
                    <Button onClick={() => handleDeleteDriver()} variant='contained' color='error' fullWidth>
                      Delete
                    </Button>
                  </Box>
                </Modal>

                <Button onClick={() => setCreateModal(true)} sx={{ marginTop: '10px' }} variant='contained' fullWidth>
                  Add new
                </Button>
                <Modal open={createModal} onClose={() => setCreateModal(false)}>
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      width: 300,
                      height: 300,
                      bgcolor: 'background.paper',
                      border: '2px solid #000',
                      boxShadow: 24,
                      p: 4,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-around',
                      alignItems: 'center',
                      gap: '10px',
                    }}
                  >
                    <TextField value={createName} onChange={(e) => setCreateName(e.target.value)} sx={{ width: '100%' }} label='Name' />
                    <TextField value={createPhone} onChange={(e) => setCreatePhone(e.target.value)} sx={{ width: '100%' }} label='Phone' />
                    <Button onClick={() => handleCreateDriver()} variant='contained' fullWidth>
                      Create
                    </Button>
                  </Box>
                </Modal>
              </Box>
            </Box>
          </Modal>
        </div>
        {mobileScreen ? (
          <Modal open={statusModal} onClose={() => setStatusModal(false)}>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 300,
                height: 300,
                bgcolor: 'background.paper',
                border: '2px solid #000',
                boxShadow: 24,
                p: 4,
              }}
            >
              <div
                className='itemStatus'
                style={{
                  display: statusModal ? 'grid' : 'none',
                  gridTemplateColumns: '1fr 1fr',
                  position: 'relative',
                  transform: 'none',
                  width: '100%',
                  height: '300px',
                  justifyContent: 'space-between',
                  overflowY: 'auto',
                  border: 'none',
                  padding: '0',
                  boxShadow: 'none',
                }}
              >
                <div
                  style={{ border: '1px solid black' }}
                  onClick={() => handleChangeStatus(statuses.find((status) => status._id === '6584a9da0ca272b8a1c94df4'))}
                  className='itemStatus__item'
                >
                  <Like width={24} height={24} />
                  <span>READY</span>
                </div>
                <div
                  style={{ border: '1px solid black' }}
                  onClick={() => handleChangeStatus(statuses.find((status) => status._id === '6584ab230ca272b8a1c94df9'))}
                  className='itemStatus__item'
                >
                  <Delivery width={24} height={24} />
                  <span>SHOPPING</span>
                </div>

                <div
                  style={{ border: '1px solid black' }}
                  onClick={() => handleChangeStatus(statuses.find((status) => status._id === '6584aad40ca272b8a1c94df7'))}
                  className='itemStatus__item'
                >
                  {/* <Checking width={24} height={24} /> */}
                  <p>👁️</p>
                  <span>CHECKING</span>
                </div>
                <div
                  style={{ border: '1px solid black' }}
                  onClick={() => handleChangeStatus(statuses.find((status) => status._id === '6584ab060ca272b8a1c94df8'))}
                  className='itemStatus__item'
                >
                  <Production width={24} height={24} />
                  <span>PRODUCTION</span>
                </div>

                <div
                  style={{ border: '1px solid black' }}
                  onClick={() => handleChangeStatus(statuses.find((status) => status._id === '658efe96c582a56609d72de8'))}
                  className='itemStatus__item'
                >
                  <CONGEDOR width={24} height={24} />
                  <span>CONGELADOR</span>
                </div>
                <div
                  style={{ border: '1px solid black' }}
                  onClick={() => handleChangeStatus(statuses.find((status) => status._id === '6584aa570ca272b8a1c94df6'))}
                  className='itemStatus__item'
                >
                  <p>⛔</p>
                  <span>NOT AVAILABLE</span>
                </div>
                <div
                  style={{ border: '1px solid black' }}
                  onClick={() => handleChangeStatus(statuses.find((status) => status._id === '6584aa210ca272b8a1c94df5'))}
                  className='itemStatus__item'
                >
                  <InProgress width={24} height={24} />
                  <span>PARCIAL</span>
                </div>
                <div
                  style={{ border: '1px solid black' }}
                  onClick={() => handleChangeStatus(statuses.find((status) => status._id === '65959de07503ef32164ba38c'))}
                  className='itemStatus__item'
                >
                  <p>🔙</p>
                  <span>DEVOLUCION</span>
                </div>

                <div style={{ border: '1px solid black' }} onClick={() => handleChangeStatus({})} className='itemStatus__add'>
                  Delete
                  <Plus
                    width={15}
                    height={15}
                    style={{
                      position: 'relative',
                      // top: '1px',
                      left: '12px',
                    }}
                  />
                </div>
              </div>
            </Box>
          </Modal>
        ) : (
          <div
            className='itemStatus'
            style={{
              display: statusModal ? 'block' : 'none',
              top: `${mobileScreen ? '70%' : '0px'}`,
              left: `${mobileScreen ? '50%' : '62%'}`,
              position: `${mobileScreen ? 'fixed' : 'absolute'}`,
              width: `${'max-content'}`,
              height: `${'max-content'}`,
            }}
          >
            <div
              onClick={() => handleChangeStatus(statuses.find((status) => status._id === '6584a9da0ca272b8a1c94df4'))}
              className='itemStatus__item'
            >
              <Like width={24} height={24} />
              <span>READY</span>
            </div>
            <div
              onClick={() => handleChangeStatus(statuses.find((status) => status._id === '6584ab230ca272b8a1c94df9'))}
              className='itemStatus__item'
            >
              <Delivery width={24} height={24} />
              <span>SHOPPING</span>
            </div>

            <div
              onClick={() => handleChangeStatus(statuses.find((status) => status._id === '6584aad40ca272b8a1c94df7'))}
              className='itemStatus__item'
            >
              {/* <Checking width={24} height={24} /> */}
              <p>👁️</p>
              <span>CHECKING</span>
            </div>
            <div
              onClick={() => handleChangeStatus(statuses.find((status) => status._id === '6584ab060ca272b8a1c94df8'))}
              className='itemStatus__item'
            >
              <Production width={24} height={24} />
              <span>PRODUCTION</span>
            </div>

            <div
              onClick={() => handleChangeStatus(statuses.find((status) => status._id === '658efe96c582a56609d72de8'))}
              className='itemStatus__item'
            >
              <CONGEDOR width={24} height={24} />
              <span>CONGELADOR</span>
            </div>
            <div
              onClick={() => handleChangeStatus(statuses.find((status) => status._id === '6584aa570ca272b8a1c94df6'))}
              className='itemStatus__item'
            >
              <p>⛔</p>
              <span>NOT AVAILABLE</span>
            </div>
            <div
              onClick={() => handleChangeStatus(statuses.find((status) => status._id === '6584aa210ca272b8a1c94df5'))}
              className='itemStatus__item'
            >
              <InProgress width={24} height={24} />
              <span>PARCIAL</span>
            </div>
            <div
              onClick={() => handleChangeStatus(statuses.find((status) => status._id === '65959de07503ef32164ba38c'))}
              className='itemStatus__item'
            >
              <p>🔙</p>
              <span>DEVOLUCION</span>
            </div>

            <div onClick={() => handleChangeStatus({})} className='itemStatus__add'>
              Delete
              <Plus
                width={15}
                height={15}
                style={{
                  position: 'relative',
                  // top: '1px',
                  left: '12px',
                }}
              />
            </div>
          </div>
        )}
        {/* <div className='tableModal__body__item'>
          <NewOrder width={30} height={30} />
          <p>New order</p>
        </div> */}
        <div
          className='tableModal__body__item'
          onClick={() => {
            handleDeleteProducts('delete')
          }}
        >
          <Delete width={30} height={30} />
          <p className='red'>Delete</p>
        </div>
        <div className='tableModal__body__item'>
          <p className='black'>Total</p>
          <p className='green'>{total.toFixed(2)}</p>
        </div>
        {localStorage.getItem('name') != 'VPF' && (
          <div className='tableModal__body__item'>
            <p className='black'>COGS</p>
            <p className='green'>{cogs.toFixed(2)}</p>
          </div>
        )}
        {localStorage.getItem('name') != 'VPF' && (
          <div className='tableModal__body__item'>
            <p className='black'>Profit</p>
            <p className='green'>{profit.toFixed(2)}</p>
          </div>
        )}
        <div className='tableModal__body__btn ' onClick={() => setSelectedItems([])}>
          x
        </div>
      </div>
    </div>
  )
}
